$primaryColor: rgb(30,163,153);
$defaultTextFamily:"Calibri";
$shareLink: rgb(30,163,153);
$background-button-config : rgb(30,163,153);
$color-button-config: white;

/************** PRIMARY COLORS **************/

$color-black: #222222;
$color-purple: #8c6fff;
$color-green: #50e3c2;
$color-cyan: #32d0f5;
$color-red: #FF6161;
$color-grey: #717171;
$color-lightgrey: #C7C7C7;
$color-ultra-lightgrey: #F9F9F9;

/************** PASTEL COLORS **************/

$pastel-ligth-yellow: rgb(30,163,153);
$pastel-yellow: rgb(30,163,153);
$pastel-ligth-purple: #E5DEFF;
$pastel-purple: #C0B0FF;
$pastel-ligth-green: #DAF9F2;
$pastel-green: #9FF0DE;
$pastel-blue: #8EE5FA;
$pastel-red: #FDDBDB;
